import "@js/parts/css";

import "@js/parts/lazyloading";
import "@js/parts/unpoly";
import swiper from '@js/parts/swiper';
import twoColSwiper from '@js/parts/twoColSwiper';

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

up.compiler('[name=swiper]', function(element) {
  if (element.getAttribute('data-index')) {
    twoColSwiper(element.classList[0], element.getAttribute('data-index'));
  } else {
    swiper(element.classList[0]);
  }
});
