import Swiper, {EffectFade, Autoplay, Pagination} from 'swiper';

Swiper.use([EffectFade, Autoplay, Pagination]);

export default function init(elementClass, index) {

  if (!document.getElementsByClassName(elementClass)) {
    return;
  }

  const swiperFullName = '.' + elementClass;

  let options = {
    loop: true,
    loopPreventsSlide: false,
    observer: true,
    observeParents: true,
    slidesPerView: 1,
    spaceBetween: 0,
    effect: "fade",
    pagination: {
      el: '.swiper--two-col-pagination-' + index,
      clickable: true,
    },
    hashNavigation: {
      watchState: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };

  const swiper = new Swiper(swiperFullName, options);
}
