import Swiper, {EffectFade, Autoplay, Navigation, Pagination} from 'swiper';

import 'swiper/css/bundle';

Swiper.use([EffectFade, Autoplay, Navigation, Pagination]);

export default function init(elementClass) {

  if (!document.getElementsByClassName(elementClass)) {
    return;
  }

  const swiperFullName = '.' + elementClass;

  let options = {
    autoplay: {
      delay: 4000
    },
    autoplayDisableOnInteraction: false,
    loop: false,
    observer: true,
    observeParents: true,
    slidesPerView: 1,
    spaceBetween: 0
  };

  if (elementClass === "swiper--hero") {
    options.effect = 'fade';
    options.speed = 1000;
    options.allowTouchMove = 'false';
    options.noSwiping = 'true';
    options.fadeEffect = {
      crossFade: true
    };
    options.allowTouchMove = false;
  } else if (elementClass === "swiper--slideshow") {
    options.autoplay = false;
    options.loop = true;
    options.navigation = {
      nextEl: '.swiper-button-next',
    };
    options.slidesPerView = 1.33;
    options.spaceBetween = 32;
    options.breakpoints = {
      768: {
        slidesPerView: 1.33,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 1.5,
        spaceBetween: 75,
      },
    };

  } else if (elementClass === "swiper--featured-projects" || elementClass === "swiper--featured-sectors") {
    options.autoplay = false;
    options.autoHeight = false;
    options.loop = false;
    options.slidesPerView = 1.15;
    options.spaceBetween = 32;
  }

  if (elementClass === "swiper--featured-projects") {
    options.breakpoints = {
      768: {
        slidesPerView: 3,
        spaceBetween: 0
      },
    };
  }

  if (elementClass === "swiper--featured-sectors") {
    options.breakpoints = {
      768: {
        slidesPerView: 4,
        spaceBetween: 0
      },
    };
  }

  const swiper = new Swiper(swiperFullName, options);
}
